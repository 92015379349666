<template>
  <div class="forget_wrap" >
    <div class="form_item" >
      <div class="form_item_label" >
        绑定手机号
      </div>
      <input class="form_input" placeholder="请输入绑定手机号" v-model="form.phone" type="number" />
    </div>

    <div class="form_item" >
      <div class="form_item_label" >
        验证码
      </div>
      <input class="form_input" placeholder="请输入验证码" v-model="form.verificationCode" type="number" :maxlength="6" />
      <div class="get_code" v-if="second <= 0" @click="getCode" >
        获取验证码
      </div>
      <div class="get_code" v-else style="font-size: 15px; opacity: 0.6">
        {{ second }}秒后重新获取
      </div>
    </div>

    <div class="form_item" >
      <div class="form_item_label" >
        新密码
      </div>
      <input class="form_input" placeholder="请输入新密码" v-model="form.password" type="password" />
    </div>

    <div class="form_item" >
      <div class="form_item_label" >
        重复密码
      </div>
      <input class="form_input" placeholder="请再次输入密码" v-model="form.repeatPassword" type="password" />
    </div>

    <van-button class="commit_button" :loading="submitting" @click="handleSubmit" >提交</van-button>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  sendSelfVerificationCode,
  updateForgetPassword
} from '@/api'
import {
  Toast
} from 'vant'
export default {
  name: 'forgetPwd',
  data() {
    return {
      submitting: false,
      form: {
        password: '',
        phone: '',
        verificationCode: '',
        repeatPassword: ''
      },
      second: 0
    }
  },
  methods: {
    isPhone(str) {
      let flag = true
      let reg = /^1\d{10}$/
      if(!reg.test(str)) {
        flag = false
      }
      return flag
    },
    getCode() {
      if(!this.form.phone) {
        Toast('请输入手机号')
        return false
      }

      if(!this.isPhone(this.form.phone)) {
        Toast('手机号格式不正确')
        return false
      }

      sendSelfVerificationCode({ phone: this.form.phone }).then(res => {
        if(res.success) {
          this.second = 60
          this.timer = setInterval(() => {
            this.second --
            if(this.second <= 0) {
              clearInterval(this.timer)
            }
          }, 1000)
        }else {
          Toast(res.msg)
        }
      })
    },
    handleSubmit() {
      if(!this.form.phone) {
        Toast('请输入手机号')
        return false
      }

      if(!this.isPhone(this.form.phone)) {
        Toast('手机号格式不正确')
        return false
      }

      if(!this.form.verificationCode) {
        Toast('请输入验证码')
        return false
      }

      if(!this.form.password) {
        Toast('请输入密码')
        return false
      }

      if(!this.form.repeatPassword) {
        Toast('请输入确认密码')
        return false
      }

      if(this.form.repeatPassword != this.form.password) {
        Toast('两次密码输入不一致')
        return false
      }

      let values = JSON.parse(JSON.stringify(this.form))
      delete values['repeatPassword']

      this.submitting = true
      updateForgetPassword({...values}).then(res => {
        this.submitting = false
        if(res.success) {
          Toast('操作成功')
          this.$router.go(-1)
        }else {
          Toast(res.msg)
        }
      })
    }
  },
  mounted() {

  }
}
</script>
